@@ -1,76 +0,0 @@
<template>
  <v-card class="pa-8" width="100%">
    <h1 style="font-weight: 800; color: #ff0000" v-if="form.role == 'EXPERT'">
      ข้อมูลผู้ให้บริการ
    </h1>
    <h1 style="font-weight: 800; color: #ff0000" v-if="form.role == 'USER'">
      ข้อมูลผู้รับบริการ
    </h1>
    <div class="fn24-weight7">User Management</div>
    <!-- <v-row>
      <v-col cols="2"><p style="font-weight:800">username</p></v-col>
      <v-col cols="4">
        <span>{{ username }}</span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="2"><p style="font-weight:800">password</p></v-col>
      <v-col cols="4">
        <span>{{ password }}</span>
      </v-col>
    </v-row> -->
    <v-row class="mt-4">
      <v-col v-for="(i, index) in form.profileImages" :key="index">
        <v-img :src="i.fileUrl" max-width="50px"></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2"><p style="font-weight: 800">ชื่อ</p></v-col>
      <v-col cols="4">
        <span>{{ form.firstName }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight: 800">นามสกุล</p></v-col>
      <v-col cols="4">
        <span>{{ form.lastName }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight: 800">หมายเลขโทรศัพท์</p></v-col>
      <v-col cols="4">
        <span>{{ form.telNumber }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight: 800">ไอดีไลน์</p></v-col>
      <v-col cols="4">
        <span>{{ form.line }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight: 800">ประเภท</p></v-col>
      <v-col cols="4">
        <span>{{ form.role }}</span>
      </v-col>
      <v-col cols="2">
        <p style="font-weight: 800">activeFlag</p>
      </v-col>
      <v-col cols="4">
        <v-switch
          v-model="form.activeFlag"
          label="ใช้งาน"
          class="mt-0"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row>
      <v-btn @click="cancel()">กลับ</v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="submit()">บันทึก</v-btn>
    </v-row>
  </v-card>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      id: "",
      scheduleItems: [
        { text: "จันทร์", value: "1" },
        { text: "อังคาร", value: "2" },
        { text: "พุธ", value: "3" },
        { text: "พฤหัสบดี", value: "4" },
        { text: "ศุกร์", value: "5" },
      ],
      form: {
        activeFlag: "",
        age: "",
        allergy: "",
        count: 1,
        createdAt: "",
        education: "",
        educationId: "",
        educationImg: "",
        email: "",
        experience: "",
        feeCurrency: "",
        feeCurrencyId: "",
        feeFrequency: "",
        feeFrequencyId: "",
        firstName: "",
        gender: "",
        id: "",
        idCardImg: "",
        isPro: "",
        isPublic: "",
        job: "",
        jobId: "",
        lastName: "",
        lat: "",
        license: "",
        licenseId: "",
        licenseNo: "",
        line: "",
        lng: "",
        maxFee: "",
        minFee: "",
        password: "",
        profileImages: [],
        province: "",
        role: "",
        schedule: "",
        school: "",
        serviceType: "",
        serviceTypeId: "",
        telCode: "",
        telNumber: "",
        updatedAt: "",
        userRequest: "",
        userSkills: "",
        workingArea: "",
        isPro: false,
        isPublic: false,
        isRequestPro: false,
      },
    };
  },
  async created() {
    var userdata = JSON.parse(Decode.decode(localStorage.getItem("userdata")));

    this.id = userdata.id;
    // this.form = userdata;
    const auth = {
      headers: {
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E`,
      },
    };
    const response = await this.axios.get(
      `${process.env.VUE_APP_API}/users/${this.id}`,
      auth
    );
    // console.log("response", response);
    this.form = response.data.data;
    console.log("userdata", this.form);
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    async submit() {
      // if (this.$refs.createForm.validate(true)) {
      var user = JSON.parse(
        Decode.decode(localStorage.getItem("userBiAdmin"))
      );
      const auth = {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E`,
        },
      };
      if (this.activeFlag == true) {
        data.activeFlag = "1";
      } else if (this.activeFlag == false) {
        data.activeFlag = "0";
      }
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/users/${this.id}`,
        this.form,
        auth
      );
      console.log("createCate", response);
      console.log(response.data.response_status);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `แก้ไขข้อมูลสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.$router.go(-1);
      } else {
        this.$swal.fire({
          icon: "error",
          text: `สร้างแบนเนอร์ไม่สำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      // }
    },
  },
};
</script>
